import {
    CREATE_SCHEDULE,
    GET_SCHEDULE,
    CREATE_SCHEDULE_ERROR,
    GET_SCHEDULE_ERROR,
    UPDATE_SCHEDULE,
    UPDATE_SCHEDULE_ERROR,
    SCHEDULE_COMMON_ERROR , 
    DELETE_SCHEDULE_SUCCESS,
    DELETE_SCHEDULE_ERROR,
    GET_TIME_SLOTS,
    GET_TIME_SLOTS_ERROR,
    BOOK_SCHEDULE,
    BOOK_SCHEDULE_ERROR,
    GET_EVENT ,
    CREATE_EVENT,
    GET_EVENT_ERROR ,
    CREATE_EVENT_ERROR,
    GET_MEETINGS,
    GET_MEETINGS_ERROR,
    SET_SCHEDULES

} from "../actions/types";


const initialState = {
    schedules: null,
    scheduleError: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_SCHEDULE : 
            return {
                scheduleCreated : action.payload
            }
        case CREATE_SCHEDULE_ERROR :
            return{
                createError : action.payload
            }
        case GET_SCHEDULE :
            return {
                schedules : action.payload
            }
        case GET_SCHEDULE_ERROR :
            return {
                getError : action.payload
            }
        case UPDATE_SCHEDULE :
            return {
                scheduleUpdated : action.payload
            }
        case UPDATE_SCHEDULE_ERROR :
            return {
                updateError : action.payload
            }
        case DELETE_SCHEDULE_SUCCESS :
            return {
                deleted : action.payload
            }
        case DELETE_SCHEDULE_ERROR :
            return {
                deleteError : action.payload
            }
        case SCHEDULE_COMMON_ERROR :
            return {
                commonError : action.payload
            }
        case GET_TIME_SLOTS :
            return{
                timeSlots : action.payload
            }
        case GET_TIME_SLOTS_ERROR :
            return{
                timeSlotsEror : action.payload
            }

        case  BOOK_SCHEDULE:
            return{
                scheduleBooked : action.payload
            }
        case  BOOK_SCHEDULE_ERROR:
            return {
                bookScheduleError : action.payload
            }
        case GET_EVENT:
            return{
                events: action.payload
            }
        case GET_EVENT_ERROR:
            return{
                event_Error: action.payload
            }
        case CREATE_EVENT:
            return{
                eventCreated: action.payload
            }
        case CREATE_EVENT_ERROR:
            return{
                createEventError: action.payload
            }
        case GET_MEETINGS:
            return{
                meetings: action.payload
            }
        case GET_MEETINGS_ERROR:
            return{
                meetingsError: action.payload
            }
        case SET_SCHEDULES:
            console.log("state.schedules" , state.schedules)
            if(state.schedules){
                console.log("state.schedules--2" , state.schedules)
            return{
                schedules :null
            }
        }
        default: return state;   
    }

}