import React, { Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, CardGroup, Card,ListGroup,Dropdown , Button , Carousel} from 'react-bootstrap';
import { FaEllipsisV , FaRegHeart , FaPlay} from 'react-icons/fa';
import { BiCommentDetail} from 'react-icons/bi';
import { IoMdHeart } from "react-icons/io";
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './streamStyle.css';
import '../../App.css';
import { decodedLoggedUser , likePosts} from '../../helpers/auth';
import { getStreamPosts } from '../../redux/actions/post.action';
import { valid, checkHTML , convertToPlainText  } from '../../utils/validations';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';
import { toast, Toaster } from "react-hot-toast";
import InfiniteScroll from 'react-infinite-scroller';
import { changePostStatus } from '../../redux/actions/post.action';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getTopStreamer } from '../../redux/actions/user.action';
import LinkRender from '../Link/LinkRender';
import { TbCalendarTime } from "react-icons/tb";
import { withRouter } from '../../wrapper/withRouter';


class StreamMain extends Component {
    constructor(props){
        super(props)
        this.state =  {
            streamPostList : [],
            loading : false , 
            page : 1 ,
            perPage : 5,
            loadingState : false ,
            isActive : true,
            userId : '' ,
            status: '1',
            streamUserList: [] ,
            visiblePosts: [],
        }
    }
    componentDidMount = async()=>{
        document.removeEventListener('mousedown', this.handleCloseOutside);
        if (this.props.auth.loggedIn) {
            let token = decodedLoggedUser()
            this.setState({userId:token.aud})
        }
        await this.getStreamPostList();  
        await  this.getTopStreamerList();

    }

    getTopStreamerList = async() =>{
        await this.props.getTopStreamer();
        if (this.props.streamUser && this.props.streamUser.success){
            this.setState({streamUserList:this.props.streamUser.topStreamer})
        }

    }

    toggleVisibility = (postId) => {
        this.setState((prevState) => {
          const { visiblePosts } = prevState;
          if (visiblePosts.includes(postId)) {
            return { visiblePosts: visiblePosts.filter((id) => id !== postId) };
          } else {
            return { visiblePosts: [...visiblePosts, postId] };
          }
        });
    };

    isPostVisible = (postId) => this.state.visiblePosts.includes(postId);
    

    UNSAFE_componentWillReceiveProps =  async nextProps =>{
        if (this.props !== nextProps) {
            var { streamList } = nextProps;
            if(streamList && streamList.streamPostList){
                if(streamList.streamPostList.streamPost && streamList.streamPostList.streamPost.length > 0){
                    this.setState({streamPostList:streamList.streamPostList.streamPost})
                }
            }
        }
    }

    getStreamPostList = async() =>{
        const {page , perPage , streamPostList} = this.state ; 
        const data = {perPage:perPage , page:page};
        if (this.state.page == 1) {
            this.setState({ loading: true })
        }
        await this.props.getStreamPosts(data);
        if(this.props?.streamList  && this.props?.streamList?.success){
            var posts = streamPostList ;
            Array.prototype.push.apply(posts, this.props?.streamList?.streamPostList?.streamPost);
            posts = posts.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
            this.setState({
                streamPostList : posts ,
                loading: false,
                loadingState: false,
            })
            let totalPages = this.props.streamList.streamPostList.streamPostMeta;
            if(posts.length !== totalPages){
                this.setState({page: page+1})
            }else{
                this.setState({ loadingState: true })
            }
        }
        if(this.props.streamError && this.props.streamError.message){
            toast.error( this.props.streamError.message , {
                position: 'top-center', 
            });
            this.setState({ loading: false })
        }
        this.setState({ loading: false })
    }

    loadMoreItems() {
        if (this.state.loadingState) {
            return;
        } else {
            this.getStreamPostList();
        }
    }

    handleLike = async(data) => {
        // if(Number(data.user.id) !== Number(this.state.userId)){
            likePosts(this.state.userId, data.id)
        // }
    }

    handleLikeLength = (post) =>{
        if(post){
            const postUserId = post.user_id

            const userFound = post.Likes.some(like => Number(like.user_id) === Number(postUserId))

            if(userFound){
                if(post.Likes && post.Likes.length > 0){
                    const length =  post.Likes.length-1 ;
                    if(length === 0){
                        return null
                    }else{
                        return length
                    }
                }
            }else{
                return post.Likes.length ;
            }
        }
    }


    copyText = () =>{
        toast.success('Link copy to clipboard', {position: 'top-center'});
    }

    handleMoreText = (id) =>{
       this.setState({showMoreText:!this.state.showMoreText})
    }
    
    handleCommentsLength = (comments) =>{
        if(comments && comments.length > 0){
            const validComments = comments.filter((item , index)=>{
                if(item.status === '1'){
                    if(item.comment_id === null){
                        return item
                    }else{
                        const reply = comments.find((c) => Number(c.id) === Number(item.comment_id));
                        if(reply && reply.status === '1'){
                            return item
                        }
                    }
                }
            });
            if(validComments && validComments.length > 0){
                return (
                    <small className='ms-2'>{validComments.length}</small>
                )
            }

        }
    }



    handleChangePostStatus = async(postId , status) =>{
        const data = {postId:postId , status:status}
        await this.props.changePostStatus(data)
        if(this.props.postStatus && this.props.postStatus.success){
            this.setState({status : status})
            toast.success(this.props.postStatus.message, {
                position: 'top-center', 
            });
        }
        if(this.props.postStatusError && this.props.postStatusError.message){
            toast.error( this.props.postStatusError.message, {
                position: 'top-center', 
            });
        }
    }


    /*********    using for share link / copy link to clipboard  ****************************/   
    handleCopy =(post) => {
        if(post.PostCategory.slug === 'text'){
            let link = `${window.location.origin}/text/${post.slug}`;
            return link

        }
        if(post.PostCategory.slug === 'film'){
            let link = `${window.location.origin}/video/${post.slug}`;
            return link

        }
        if(post.PostCategory.slug === 'audio'){
            if (post.Postmeta.length) {
                let link = `${window.location.origin}/audio/album/${post.slug}`;
                return link
                
            }
            else {
                let link = `${window.location.origin}/audio/single/${post.slug}`;
                return link
            }
        }
        if(post.PostCategory.slug === 'visual'){
            let link = `${window.location.origin}/visual/${post.slug}`;
            return  link
            
        }
        if(post.PostCategory.slug === 'Live-Video'){
            let link = `${window.location.origin}/Live-Video/${post.slug}`;
            return  link
        }
        if(post.PostCategory.slug === 'link'){
            let link = post.media
            return link
        }
       
    }


    renderStreamList = () =>{
        const {streamPostList} = this.state ; 
        if(streamPostList && streamPostList.length > 0 ){
            return (
                <>
                    {
                        streamPostList.map(( item , idx)=>{
                            if(item && item.PostCategory){
                                if(item.PostCategory.slug === 'audio'){
                                    return this.renderAudio(item)
                                }
                                if(item.PostCategory.slug === 'text'){
                                    return this.renderText(item)
                                }
                                if(item.PostCategory.slug === 'film'){
                                    return this.renderVideo(item)
                                }
                                if(item.PostCategory.slug === 'visual'){
                                    return this.renderVisual(item)
                                }
                                if(item.PostCategory.slug === 'Live-Video'){
                                    return this.renderLive(item)
                                }
                            }    
                        })
                        
                    }    
                </>
            )

        }
    }

    renderAudio = (audio) =>{
        const {userId} = this.state
        if(valid(audio)){
            if (audio.Postmeta.length) {
                var url = '';
                url = `/audio/album/${audio.slug}`;
            }
            else {
                url = `/audio/single/${audio.slug}`;
            }
            return (
                <div className='single--stream--post mt-4'>
                    <div className="Gr--stream-user">
                        {userId == audio.user.id &&
                            <Dropdown className='edit--post--dropdown'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Link className='dropdown-item' to={`/edit${url}`}>Edit</Link>
                                    <Dropdown.Item>
                                        <CopyToClipboard text={this.handleCopy(audio)} onCopy={()=>{this.copyText()}}>
                                            <p>Share Link</p>
                                        </CopyToClipboard>   
                                    </Dropdown.Item>
                                    <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(audio.id , '2')}>Move to Drafts</Link>
                                    <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(audio.id , '0')}>Delete</Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        <div className="Gr--streamDesc">
                            <Link to={`/${audio.user.username}`}>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={(audio.user && valid(audio.user.image)) ? audio.user.image : "/images/user.png"}
                                    alt={audio.name}
                                />
                            </Link>
                        </div>
                        <div className="Gr--streamUser">
                            <Link to={`/${audio.user.username}`}>
                                <h4>{(audio.user && valid(audio.user.first))? audio.user.first : ''}</h4>
                            </Link>    
                                <p className='mb-0'><span>{moment(audio.createdAt).format('LL')}</span> </p>
                           
                        </div>
                    </div>
                    <div className='Gr--streamPost--content'>
                        <Link to={url}>
                            <LazyLoadImage
                                width="100%"
                                placeholderSrc="/images/placeholder.gif"
                                effect="blur"
                                src={valid(audio.thumbnail) ? audio.thumbnail: '/images/thumb-400kb.jpg'}
                                alt={audio.name}
                            /> 
                        </Link>
                    </div>
                    <div class="Gr--streamPost--content contextonlywrap">
                    <Link to={url}><h2 className='mt-3' style={{cursor:"pointer"}}>{checkHTML(audio.name) ? convertToPlainText(audio.name) : audio.name}</h2></Link>
                    {audio.description && audio.description.length > 600 ?
                        <>
                            <div  className={this.isPostVisible(audio.id) ? 'streamDec' : 'streamDec streamDecShow'} dangerouslySetInnerHTML={{ __html: audio.description}} />
                            <a className='showHidebtn' onClick={()=>this.toggleVisibility(audio.id)}>{this.isPostVisible(audio.id) ? 'Show Less' : 'Show More '}</a>
                        </>
                        :
                            <div  className='streamDec' dangerouslySetInnerHTML={{ __html: audio.description}} />
                    }
                    </div>
                    <div className='stream--postActions'>
                        <ListGroup>
                            <ListGroup.Item >
                                {(audio .Likes && audio.Likes.length > 0) && <span className='me-2'>{this.handleLikeLength(audio)}</span>}
                                {audio.Likes.some(like => like.user_id === userId) ? (
                                    // <IoMdHeart  onClick = {()=>this.handleLike(audio)}/>) : (<FaRegHeart onClick = {()=>this.handleLike(audio)}/>
                                    
                                    <IoMdHeart  onClick = {()=>this.handleLike(audio)}/>) : (<FaRegHeart onClick = {()=>this.handleLike(audio)}/>
                                )}
                            </ListGroup.Item>
                            
                            {(Number(audio.user.id) !== Number(this.state.userId) && audio.user.booked_schedulers && audio.user.booked_schedulers.length > 0)&&
                                <ListGroup.Item className='ms-2'>
                                    <TbCalendarTime onClick={()=>this.props.navigate(`/booking/${audio.user.id}`)}/>
                                </ListGroup.Item>
                            }
                            <ListGroup.Item className='ms-auto'>
                                {audio.commenting && 
                                    <>
                                        <Link to={url}>
                                            <BiCommentDetail/>
                                            <span className='me-2'>
                                                {(audio.comments && audio.comments.length > 0)&&
                                                    this.handleCommentsLength(audio.comments)
                                                }
                                            </span>
                                        </Link>
                                    </>
                                }    
                            </ListGroup.Item>
                            
                        </ListGroup>
                    </div>
                </div>
            )
        }

    }


    renderText = (text) =>{
        const {userId} = this.state
        if(valid(text)){
            return (
                <div className='single--stream--post mt-4'>
                    <div className="Gr--stream-user">
                        {(userId == text.user.id ) &&
                            <Dropdown className='edit--post--dropdown'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Link className='dropdown-item' to={`/edit-text/${text.slug}`}>Edit</Link>
                                    <Dropdown.Item>
                                        <CopyToClipboard text={this.handleCopy(text)} onCopy={()=>{this.copyText()}}>
                                            <p>Share Link</p>
                                        </CopyToClipboard>   
                                    </Dropdown.Item>
                                    <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(text.id , '2')}>Move to Drafts</Link>
                                    <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(text.id , '0')}>Delete</Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        <div className="Gr--streamDesc">
                            <Link to={`/${text.user.username}`}>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={(text.user && valid(text.user.image)) ? text.user.image : "/images/user.png"}
                                    alt={text.name}
                                />
                            </Link>
                        </div>
                        <div className="Gr--streamUser">
                            <Link to={`/${text.user.username}`}>
                                <h4>{(text.user && valid(text.user.first))? text.user.first : ''}</h4>
                                <p><span>{moment(text.createdAt).format('LL')}</span> </p>
                            </Link>
                        </div>
                    </div>
                    {(text.thumbnail)&&
                        <div className='Gr--streamPost--content'>
                            <Link to={`/text/${text.slug}`}>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={valid(text.thumbnail) ? text.thumbnail : '/images/thumbnail.jpg'} 
                                    alt={text.name}
                                /> 
                            </Link>
                        </div>
                    }
                    <div className='Gr--streamPost--content contextonlywrap'>
                        <Link to={`/text/${text.slug}`}>
                            <h2 className='mt-3'>{checkHTML(text.name) ? convertToPlainText(text.name) : text.name}</h2>
                        </Link>
                        {text.description && text.description.length > 600 ? 
                            <>
                            
                                <div  className={this.isPostVisible(text.id) ? 'streamDec' : 'streamDec streamDecShow'} dangerouslySetInnerHTML={{ __html: text.description}} style={{"color":"white" ,"text":"white"}}/>
                            
                                <a className='showHidebtn' onClick={()=>this.toggleVisibility(text.id)}>{this.isPostVisible(text.id) ? 'Show Less' : 'Show More '}</a>
                            </>
                            :
                                <div  className='streamDec' dangerouslySetInnerHTML={{ __html: text.description}} />
                        }
                        {text.media !=='' ? 
                            <LinkRender post= {text}/>
                            : null
                        }
                    </div>
                    
                    
                    <div className='stream--postActions'>
                        <ListGroup>
                            <ListGroup.Item >
                                {(text.Likes && text.Likes.length > 0) && <span className='me-2'>{this.handleLikeLength(text)}</span>}
                                {text.Likes.some(like => like.user_id === userId) ? (
                                    <IoMdHeart  onClick = {()=>this.handleLike(text)}/>) : (<FaRegHeart onClick = {()=>this.handleLike(text)}/>
                                )}
                            </ListGroup.Item>
                           
                            {(Number(text.user.id) !== Number(this.state.userId) && text.user.booked_schedulers && text.user.booked_schedulers.length > 0)&&
                                <ListGroup.Item className='ms-2'>
                                    <TbCalendarTime onClick={()=>this.props.navigate(`/booking/${text.user.id}`)}/>
                                </ListGroup.Item>
                            }
                             <ListGroup.Item className='ms-auto'>
                                {text.commenting && 
                                    <>
                                    <Link to={`/text/${text.slug}`}>
                                            <BiCommentDetail/>
                                            <span className='ms-2'>
                                                {(text.comments && text.comments.length > 0)&&
                                                    this.handleCommentsLength(text.comments)
                                                }
                                            </span>
                                        </Link>
                                    </>
                                }    
                            </ListGroup.Item>
                           
                        </ListGroup>
                    </div>
                </div>
            )
        }

    }

    renderVideo = (video) => {
        const {userId} = this.state;
        if(valid(video)){
            return (
                <div className='single--stream--post mt-4'>
                    <div className="Gr--stream-user">
                        {userId == video.user.id &&
                            <Dropdown className='edit--post--dropdown'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                <Link className='dropdown-item' to={`/edit-video/${video.slug}`}>Edit</Link>
                                    <Dropdown.Item>
                                        <CopyToClipboard text={this.handleCopy(video)} onCopy={()=>{this.copyText()}}>
                                            <p>Share Link</p>
                                        </CopyToClipboard>   
                                    </Dropdown.Item>
                                    <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '2')}>Move to Drafts</Link>
                                    <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '0')}>Delete</Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        <div className="Gr--streamDesc">
                            <Link to={`/${video.user.username}`}>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={(video.user && valid(video.user.image)) ? video.user.image : "/images/user.png"}
                                    alt={video.name}
                                />
                            </Link>
                        </div>
                        <div className="Gr--streamUser">
                            <Link to={`/${video.user.username}`}>
                                <h4>{(video.user && valid(video.user.first))? video.user.first : ''}</h4>
                                <p><span>{moment(video.createdAt).format('LL')}</span> </p>
                            </Link>
                        </div>
                    </div>
                    <div className='Gr--streamPost--content'>
                        <Link to={`/video/${video.slug}`}>
                            <LazyLoadImage
                                width="100%"
                                placeholderSrc="/images/placeholder.gif"
                                effect="blur"
                                src={valid(video.thumbnail) ? video.thumbnail : '/images/thumbnail.jpg'} 
                                alt={video.name}
                            /> 
                        </Link>
                    </div>
                    <div className='Gr--streamPost--content contextonlywrap'>
                    <Link to={`/video/${video.slug}`}>
                        <h2 className='mt-3'>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</h2>
                    </Link>
                    {video.description && video.description.length > 600 ? 
                        <>
                           
                            <div  className={this.isPostVisible(video.id) ? 'streamDec' : 'streamDec streamDecShow'} dangerouslySetInnerHTML={{ __html: video.description}} style={{"color":"white" ,"text":"white"}}/>
                           
                            <a className='showHidebtn' onClick={()=>this.toggleVisibility(video.id)}>{this.isPostVisible(video.id) ? 'Show Less' : 'Show More '}</a>
                        </>
                        :
                            <div  className='streamDec' dangerouslySetInnerHTML={{ __html: video.description}} />
                    }
                    </div>
                    <div className='stream--postActions'>
                        <ListGroup>
                            <ListGroup.Item>
                                {(video.Likes && video.Likes.length > 0) && <span className='me-2'>{this.handleLikeLength(video)}</span>}
                                {video.Likes.some(like => like.user_id === userId) ? (
                                    // <IoMdHeart  onClick = {()=>this.handleLike(video)}/>) : (<FaRegHeart onClick = {()=>this.handleLike(video)}/>
                                    <IoMdHeart  onClick = {()=>this.handleLike(video)}/>) : (<FaRegHeart onClick = {()=>this.handleLike(video)}/>
                                )}
                            </ListGroup.Item>
                           
                            {(Number(video.user.id) !== Number(this.state.userId) && video.user.booked_schedulers && video.user.booked_schedulers.length > 0)&&
                                <ListGroup.Item className='ms-2'>
                                    <TbCalendarTime onClick={()=>this.props.navigate(`/booking/${video.user.id}`)}/>
                                </ListGroup.Item>
                            }
                            <ListGroup.Item className='ms-auto'>
                                <Link to={`/video/${video.slug}`}>
                                    <FaPlay className='ms-2' />
                                </Link>
                            </ListGroup.Item>
                                {video.commenting && 
                                    <ListGroup.Item className='ms-2'>
                                        <Link to={`/video/${video.slug}`}>
                                            <BiCommentDetail/>
                                            <span>
                                                {(video.comments && video.comments.length > 0)&&
                                                    this.handleCommentsLength(video.comments)
                                                }
                                            </span>
                                        </Link>
                                    </ListGroup.Item>
                                }    
                            
                            
                            
                            {/* <ListGroup.Item>
                                
                            </ListGroup.Item> */}
                        </ListGroup>
                    </div>
                </div>
            )
        }

    }


    renderVisual = (visual) =>{
        const {userId} = this.state;
        if(valid(visual)){
            return (
                <div className='single--stream--post mt-4'>
                    <div className="Gr--stream-user">
                        {userId == visual.user.id &&
                            <Dropdown className='edit--post--dropdown'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FaEllipsisV /> 
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Link className='dropdown-item' to={`/edit-visual/${visual.slug}`}>Edit</Link>
                                    <Dropdown.Item>
                                        <CopyToClipboard  text={this.handleCopy(visual)} onCopy={()=>{this.copyText()}}>
                                            <p>Share Link</p>
                                        </CopyToClipboard>   
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={()=>this.handleChangePostStatus(visual.id,'2')}>Move to Drafts</Dropdown.Item>
                                    <Dropdown.Item onClick={()=>this.handleChangePostStatus(visual.id,'0')}>Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        <div className="Gr--streamDesc">
                            <Link to={`/${visual.user.username}`}>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={(visual.user && valid(visual.user.image)) ? visual.user.image : "/images/user.png"}
                                    alt={visual.name}
                                />
                            </Link>
                        </div>
                        <div className="Gr--streamUser">
                            <Link to={`/${visual.user.username}`} >
                                <h4>{(visual.user && valid(visual.user.first))? visual.user.first : ''}</h4>
                                <p><span>{moment(visual.createdAt).format('LL')}</span> </p>
                            </Link>
                        </div>
                    </div>
                    <div className='Gr--streamPost--content'>
                        {this.handlePhotos(visual)}
                    </div>
                    <div class="Gr--streamPost--content contextonlywrap">
                    <Link to={`/visual/${visual.slug}`}>
                        <h2 className='mt-3'>{checkHTML(visual.name) ? convertToPlainText(visual.name) : visual.name}</h2>
                    </Link>
                    {visual.description && visual.description.length > 600 ?
                        <>
                           
                            <div  className={this.isPostVisible(visual.id) ? 'streamDec' : 'streamDec streamDecShow'} dangerouslySetInnerHTML={{ __html: visual.description}} />
                           
                            <a className='showHidebtn' onClick={()=>this.toggleVisibility(visual.id)}>{this.isPostVisible(visual.id) ? 'Show Less' : 'Show More '}</a>
                        </>
                        :
                            <div  className='streamDec'   dangerouslySetInnerHTML={{ __html: visual.description}} />
                    }
                    </div>
                    <div className='stream--postActions'>
                        <ListGroup>
                            <ListGroup.Item>
                                {(visual.Likes && visual.Likes.length > 0) && <span className='me-2'>{this.handleLikeLength(visual)}</span>}
                                {visual.Likes.some(like => like.user_id === userId) ? (
                                    // <IoMdHeart  onClick = {()=>this.handleLike(visual)}/>) : (<FaRegHeart onClick = {()=>this.handleLike(visual)}/>
                                    <IoMdHeart  onClick = {()=>this.handleLike(visual)}/>) : (<FaRegHeart onClick = {()=>this.handleLike(visual)}/>
                                )}
                            </ListGroup.Item>
                            {(Number(visual.user.id) !== Number(this.state.userId) && visual.user.booked_schedulers && visual.user.booked_schedulers.length > 0)&&
                                <ListGroup.Item className='ms-2'>
                                    <TbCalendarTime onClick={()=>this.props.navigate(`/booking/${visual.user.id}`)}/>
                                </ListGroup.Item>
                            }
                            <ListGroup.Item className='ms-auto'>
                               {visual.commenting && 
                                   <>
                                       <Link to={`/visual/${visual.slug}`}>
                                           <BiCommentDetail/>
                                           <span>
                                               {(visual.comments && visual.comments.length > 0)&&
                                                   this.handleCommentsLength(visual.comments)
                                               }
                                           </span>
                                       </Link>
                                   </>
                                }        
                           </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            )
        }

    }


    renderLive = (video) =>{
        const {userId} = this.state;
        const check = ['null' , null , [] , '' , undefined , 'undefined']
            return (
                <div className='single--stream--post mt-4'>
                    {video.action === 'online' 
                        ?
                            <button className='liveButton'>Live</button>
                       :   null 
                    }
                    <div className="Gr--stream-user">
                        {userId == video.user.id &&
                            <Dropdown className='edit--post--dropdown'>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FaEllipsisV />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Link className='dropdown-item' to='/live' state={{post:video}}>Edit</Link>
                                    <Dropdown.Item>
                                        <CopyToClipboard text={this.handleCopy(video)} onCopy={()=>{this.copyText()}}>
                                            <p className='m-0   '>Share Link</p>
                                        </CopyToClipboard>   
                                    </Dropdown.Item>
                                    <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '2')}>Move to Drafts</Link>
                                    <Link className='dropdown-item' onClick={()=>this.handleChangePostStatus(video.id , '0')}>Delete</Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        <div className="Gr--streamDesc">
                            <Link to={`/${video.user.username}`}>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={(video.user && valid(video.user.image)) ? video.user.image : "/images/user.png"}
                                    alt={video.name}
                                />
                            </Link>
                        </div>
                        <div className="Gr--streamUser">
                            <Link to={`/${video.user.username}`} >
                                <h4>{(video.user && valid(video.user.first))? video.user.first : ''}</h4>
                                <p><span>{moment(video.createdAt).format('LL')}</span> </p>
                            </Link>
                        </div>
                    </div>
                    <div className='Gr--streamPost--content'>
                        <Link to={`/live-video/${video.slug}`}>
                            <LazyLoadImage
                                width="100%"
                                placeholderSrc="/images/placeholder.gif"
                                effect="blur"
                                src={video.thumbnail || ''} 
                                alt={video.name}
                            /> 
                        </Link>
                    </div>
                    <div class="Gr--streamPost--content contextonlywrap">
                        <Link to={`/live-video/${video.slug}`}>
                            <h2 className='mt-3'>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</h2>
                        </Link>
                        {video.description && video.description.length > 600 ?
                            <>
                            
                                <div  className={this.isPostVisible(video.id) ? 'streamDec' : 'streamDec streamDecShow'} dangerouslySetInnerHTML={{ __html: video.description}} />
                            
                                <a className='showHidebtn' onClick={()=>this.toggleVisibility(video.id)}>{this.isPostVisible(video.id) ? 'Show Less' : 'Show More '}</a>
                            </>
                            :
                                <div  className='streamDec' dangerouslySetInnerHTML={{ __html: video.description}} />
                        }
                    </div>
                    <div className='stream--postActions'>
                        <ListGroup>
                            <ListGroup.Item>
                                {(video.Likes && video.Likes.length > 0) && <span className='me-2'>{this.handleLikeLength(video)}</span>}
                                {video.Likes.some(like => like.user_id === userId) ? (
                                    <IoMdHeart  onClick = {()=>this.handleLike(video)}/>) : (<FaRegHeart onClick = {()=>this.handleLike(video)}/>
                                )}
                            </ListGroup.Item>
                            
                            {(Number(video.user.id) !== Number(this.state.userId) && video.user.booked_schedulers && video.user.booked_schedulers.length > 0)&&
                                <ListGroup.Item className='ms-2'>
                                    <TbCalendarTime onClick={()=>this.props.navigate(`/booking/${video.user.id}`)}/>
                                </ListGroup.Item>
                            }
                            <ListGroup.Item className='ms-auto'>
                               {video.commenting && 
                                   <>
                                       
                                       <Link to={`/live-video/${video.slug}`}>
                                           <BiCommentDetail/>
                                           <span>
                                               {(video.comments && video.comments.length > 0)&&
                                                   this.handleCommentsLength(video.comments)
                                               }
                                           </span>
                                       </Link>
                                   </>
                               }    
                           </ListGroup.Item>
                            
                        </ListGroup>
                    </div>
                </div>
            )
        // }

    }


    renderStreamUser = () =>{
        const {streamUserList} = this.state ;
        if(streamUserList && streamUserList.length > 0){
            return (
                <>
                    <h3>Highlighted Creators</h3>
                    <ul class="streamerMList">
                        {
                            streamUserList.map((user , idx)=>{
                                return (
                                    <li key={idx}>
                                        <Link to={`/${user.username}`}>
                                            <span class="streamerIcon primeUser"><img src={user.image || "/images/user.png"}/></span>
                                            <p>{user.first}</p>
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </>
            )
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.status !== this.state.status){
            this.setState({
                streamPostList : [],
                loading : false , 
                page : 1 ,
                perPage : 10 ,
                loadingState : false ,
                isActive : true,
                status: '1'
            },()=>this.getStreamPostList())
        }
    }



    handlePhotos = (photos) =>{

        <div className='Gr--streamPost--content'>
        </div>
        if (photos){
            if(photos.Postmeta.length > 1){
                return(
                    <Carousel interval={null}  cols={2} rows={1} gap={10}>
                        {photos.Postmeta.map((item , ind) => (
                            <Carousel.Item key={ind}>
                                <Link to={`/visual/${photos.slug}`}>
                                    <LazyLoadImage
                                        width="100%"
                                        placeholderSrc="/images/placeholder.gif"
                                        effect="blur"
                                        src={item.meta_value} 
                                        alt={photos.name}
                                    /> 
                                </Link>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                )    
            }else{
                return (
                    // <img src={(photos.Postmeta && photos.Postmeta.length > 0) ? photos.Postmeta[0].meta_value : photos.thumbnail} />
                    <Link to={`/visual/${photos.slug}`}>
                        <LazyLoadImage
                            width="100%"
                            placeholderSrc="/images/placeholder.gif"
                            effect="blur"
                            src={photos.thumbnail || photos.Postmeta[0].meta_value }
                            alt={photos.name}
                        /> 
                    </Link> 
                    
                )
            }
        }
        
    }



    render () {

        const {loading , streamPostList , loadingState , page } = this.state ;

        return (
            <>
                <PageHeader />
                <div className='page--content'>
                    <SidebarMenu />
                    <div className='discover--module common--wrapper stream--page pb-4'>
                        <Container>
                            <Row id="scrollableDiv" ref={(ref) => this.scrollParentRef = ref} style={{ height: "100%" }}>
                                <Col sm={12} lg={9} id="scrollableDiv" ref={(ref) => this.scrollParentRef = ref} style={{ height: "100%" }}>
                                    {!loading  
                                    
                                        ?   (streamPostList && streamPostList.length > 0) 
                                        
                                            ?   <>
                                                    <div className='scrolbarSlde'>
                                                        <CardGroup>
                                                            
                                                                <Card>
                                                                    <Link to='/visual'>
                                                                        <Card.Img variant="top" src="https://storage.googleapis.com/video.thegalaxy.me/e1f3b54b-e512-4c03-a33c-0b49a8a4f74e.jpg" />
                                                                        <Card.Body>
                                                                            <Card.Title>Photo</Card.Title>
                                                                        </Card.Body>
                                                                    </Link>
                                                                </Card>
                                                            
                                                            <Card>
                                                                <Link to='/video'>
                                                                    <Card.Img variant="top" src="https://storage.googleapis.com/video.thegalaxy.me/e1f3b54b-e512-4c03-a33c-0b49a8a4f74e.jpg" />
                                                                    <Card.Body>
                                                                        <Card.Title>Video</Card.Title>
                                                                    </Card.Body>
                                                                </Link>
                                                            </Card>
                                                            <Card>
                                                                <Link to='/live-video'>
                                                                    <Card.Img variant="top" src="https://storage.googleapis.com/video.thegalaxy.me/e1f3b54b-e512-4c03-a33c-0b49a8a4f74e.jpg" />
                                                                    <Card.Body>
                                                                        <Card.Title>Live</Card.Title>
                                                                    </Card.Body>
                                                                </Link>
                                                            </Card>
                                                            <Card>
                                                                <Link to ='/audio'>
                                                                    <Card.Img variant="top" src="https://storage.googleapis.com/video.thegalaxy.me/e1f3b54b-e512-4c03-a33c-0b49a8a4f74e.jpg" />
                                                                    <Card.Body>
                                                                        <Card.Title>Audio</Card.Title>
                                                                    </Card.Body>
                                                                </Link>
                                                            </Card>
                                                            <Card>
                                                                <Link to ='/marketplace'>
                                                                    <Card.Img variant="top" src="https://storage.googleapis.com/video.thegalaxy.me/e1f3b54b-e512-4c03-a33c-0b49a8a4f74e.jpg" />
                                                                    <Card.Body>
                                                                        <Card.Title>Market</Card.Title>
                                                                    </Card.Body>
                                                                </Link>
                                                            </Card>
                                                            <Card>
                                                                <Link to = '/text'>
                                                                    <Card.Img variant="top" src="https://storage.googleapis.com/video.thegalaxy.me/e1f3b54b-e512-4c03-a33c-0b49a8a4f74e.jpg" />
                                                                    <Card.Body>
                                                                        <Card.Title>Text</Card.Title>
                                                                    </Card.Body>
                                                                </Link>
                                                            </Card>
                                                        </CardGroup>
                                                        {/* <Link className='rightangle'> <FaAngleRight /> </Link>  */}
                                                    </div>                                  
                                                        <InfiniteScroll
                                                            pageStart={0}
                                                            loadMore={() => this.loadMoreItems()}
                                                            hasMore={!loadingState}
                                                            loader={
                                                                <Col sm={12}>
                                                                    <div className='text-center spinner--loader'></div>
                                                                </Col>
                                                            }
                                                            scrollableTarget="scrollableDiv"
                                                            getScrollParent={() => this.scrollParentRef}
                                                            window={false}
                                                        >
                                                            {this.renderStreamList()}
                                                        </InfiniteScroll>
                                                </>
                                            :   <h2 className="mt-5 text-center">Posts Not Found</h2>  
                                        :   <div className='text-center spinner--loader'></div>      
                                    }            
                                </Col>     
                                <Col sm={12} lg={3}>
                                    {this.renderStreamUser()}
                                </Col>
                            </Row>
                        </Container>
                        <Toaster/>
                       
                    </div>
                </div>
            </>
        );
    }    
}

const maopStateToProps = (state) => {
    return{
        auth : state.auth,
        postStatus : state.post.changeStatusSuccess,
        postStatusError: state.post.changeStatusError,
        streamList: state?.stream?.streamPostList,
        streamError: state.stream.streamPostError,
        streamUser: state.user.streamUserList
    }    

}


const dispatchActions = {getStreamPosts , changePostStatus , getTopStreamer} ; 

export default connect(maopStateToProps, dispatchActions)(withRouter(StreamMain));