
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, ListGroup, Button, Row, Col , InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { MdClose } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-hot-toast";
import * as Auth from '../../helpers/auth';
import {createSchedule , getSchedules , setSchedules} from '../../redux/actions/schedule.action';
import moment from 'moment-timezone';


function MeetingScheduler(props) {
  const [meetingDuration, setMeetingDuration] = useState(30); // Default to 15 minutes
  const [timeOptions, setTimeOptions] = useState([]);
  const [endTimeOptions, setEndTimeOptions] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());
  const [isDisable , setIsDisable] =  useState(false);
  const dispatch = useDispatch();
  const timeZones = moment.tz.names();
  const [errors, setErrors] = useState({});
  const [dayTimeFields, setDayTimeFields] = useState({
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  });
  const [dayCheckboxes, setDayCheckboxes] = useState({
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
  });

  const navigate = useNavigate();
  const scheduleState = useSelector(state => state.schedule);

  const removeDuplicateTimeSlots = (events) => {
    const uniqueEvents = {};
    events.forEach(event => {
      const { event_start_time , event_start, event_end } = event;
      const date = new Date(event_start_time).toISOString().split('T')[0]
      // Use the date, start, and end as a unique key to prevent duplication
      const eventKey = `${date}-${event_start}-${event_end}`;
      // If this key does not exist in uniqueEvents, add it
      if (!uniqueEvents[eventKey]) {
        uniqueEvents[eventKey] = { date, event_start, event_end };
      }
    });
  
    // Return an array of unique events
    return Object.values(uniqueEvents);
  };

  // Fetch Logged User and Schedules
  useEffect(() => {
    generateTimeOptions(meetingDuration);
    getSchedulesList();
  },[]);

  useEffect(()=>{
    if(scheduleState && scheduleState.schedules){
      if(scheduleState.schedules.schedules && scheduleState.schedules.schedules.length > 0){
        loadSavedAvailability(scheduleState.schedules.schedules)
      }else{
        const TimeFields = dayTimeFields
        Object.keys(TimeFields).forEach(day => {
          if (TimeFields[day].length === 0) {
            TimeFields[day].push({ start: '09:00am', end: '05:00pm' }); // Set default time slot
            // Do not update the checkbox; it remains false
          }
        });
        setDayTimeFields(TimeFields);
      }
    }
  },[scheduleState])


  const getSchedulesList = () =>{
    const userData = Auth.decodedLoggedUser();
    dispatch(getSchedules({userId:userData.aud , eventId:props.event.id}))
  }

  const loadSavedAvailability = (eventSlots) => {
    const updatedDayTimeFields = { ...dayTimeFields };
    const updatedDayCheckboxes = dayCheckboxes ;
    if(eventSlots && eventSlots.length > 0){
      const uniqueTimeSlots = removeDuplicateTimeSlots(eventSlots);
      uniqueTimeSlots.forEach(event => {
        // Parse the event date and extract the day of the week (e.g., 'tue', 'wed')
        const eventDate = moment(new Date(event.date));
        const dayOfWeek = eventDate.format('ddd').toLowerCase();

        // Ensure the day exists in dayTimeFields (e.g., 'tue', 'wed')
        if (updatedDayTimeFields[dayOfWeek]) {
          // Check if the time slot already exists to avoid duplicates
          const existingTimeSlot = updatedDayTimeFields[dayOfWeek].find(
            timePair => timePair.start === event.event_start && timePair.end === event.event_end
          );

          // If the time slot does not exist, add it
          if (!existingTimeSlot) {
            updatedDayTimeFields[dayOfWeek].push({ start: event.event_start, end: event.event_end });
            updatedDayCheckboxes[dayOfWeek] = true
          }
        }
      });
      // Add default time slots for days without any time slots
      Object.keys(updatedDayTimeFields).forEach(day => {
        if (updatedDayTimeFields[day].length === 0) {
          updatedDayTimeFields[day].push({ start: '09:00am', end: '05:00pm' }); // Set default time slot
          // Do not update the checkbox; it remains false
        }
      });
      setDayTimeFields(updatedDayTimeFields);
      setDayCheckboxes(updatedDayCheckboxes);
    }
  };

  useEffect(() => {
    if (scheduleState.error) {
      toast.error(scheduleState.error.message);
      setIsDisable(false);
    }
    if (!scheduleState.error) {
      if (scheduleState.scheduleCreated && scheduleState.scheduleCreated.success) {
        toast.success('Events created.', {
          position: 'top-center',
        });
        setIsDisable(false)
      }
    }
  }, [scheduleState]);

  const generateTimeOptions = () => {
    const startOptions = [];
    const endOptions = [];
    const start = moment().startOf('day').hour(0).minute(0);
    const end = moment().endOf('day').hour(23).minute(59);
    let currentTime = start.clone();
    // Generate time options in 15-minute intervals
    while (currentTime.isBefore(end) || currentTime.isSame(end)) {
      startOptions.push(currentTime.format('hh:mma'));
      currentTime = currentTime.add(props.event.duration === 45 ? 60 : props.event.duration , 'minutes'); // Fixed 15-minute gap
    }
    setTimeOptions(startOptions);
    setEndTimeOptions(startOptions); // Same time options for both start and end
  };
  
  
  const handleAddTimeField = (day) => {
    const hasErrors = Object.values(errors[day] || {}).some(error => error !== '');
    if (hasErrors) {
      return;
    }
    setDayTimeFields(prevState => {
      const currentFields = [...prevState[day]];
      let newStartTime;
      if (currentFields.length > 0) {
        const lastField = currentFields[currentFields.length - 1];
        newStartTime = moment(lastField.end, 'hh:mma').format('hh:mma'); // Increment by 1 hour
      } else {
        newStartTime = '09:00am';
      }
      const newEndTime = moment(newStartTime, 'hh:mma').add(1, 'hours').format('hh:mma'); // Add 1 hour for new end time
      // Check for duplicate
      const isDuplicate = currentFields.some(field => field.start === newStartTime && field.end === newEndTime);
      if (isDuplicate) {
        toast.error('Cannot add a new time field: the time interval is duplicated.');
        return prevState;
      }
      const updatedFields = [...currentFields, { start: newStartTime, end: newEndTime }];

      return {
        ...prevState,
        [day]: updatedFields,
      };
    });
  };

  

  // Handle Removing a Time Field
  const handleRemoveTimeField = (day, index) => {
    setDayTimeFields(prevState => {
      const updatedFields = prevState[day].filter((_, i) => i !== index);
      const newState = {
        ...prevState,
        [day]: updatedFields,
      };
      // Automatically uncheck the checkbox if all time fields are removed
      if (updatedFields.length === 0) {
        setDayCheckboxes(prevCheckboxes => ({
          ...prevCheckboxes,
          [day]: false,
        }));
      }
      return newState;
    });
  };

  // Handle Checkbox Toggle
  const handleCheckboxChange = (day) => {
    setDayCheckboxes(prevState => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  // Handle Time Zone Change
  const handleTimeZoneChange = (e) => {
    setSelectedTimeZone(e.target.value);
  };

  // Get Date Object for a Specific Day
  const getDateForDay = (day) => {
    const today = moment().startOf('day');
    const targetDayIndex = moment().day(day).day(); // 'sun' => 0, 'mon' => 1, etc.
    const diff = targetDayIndex - today.day();
    return today.clone().add(diff >= 0 ? diff : diff + 7, 'days');
  };
 

  // Handle End Time Change


  const validateTimeSlot = (day , index , timeFields) => {
    const timeSlots = timeFields[day]
    const newStart = moment(timeSlots[index].start, 'hh:mma');
    const newEnd = moment(timeSlots[index].end, 'hh:mma');

    for (let i = 0; i < timeSlots.length; i++) {
      if (i === index) continue; // Skip the current time slot being updated
  
      const existingStart = moment(timeSlots[i].start, 'hh:mma');
      const existingEnd = moment(timeSlots[i].end, 'hh:mma');
  
      // Check for overlap or equality with existing slots
      const startOverlap = newStart.isBetween(existingStart, existingEnd, null, '[]') || newStart.isSame(existingStart);
      const endOverlap = newEnd.isBetween(existingStart, existingEnd, null, '[]') || newEnd.isSame(existingEnd);
      const fullOverlap = newStart.isBefore(existingStart) && newEnd.isAfter(existingEnd);
  
      if (startOverlap || endOverlap || fullOverlap || newStart.isSameOrAfter(newEnd)) {
        return false; // Validation fails if any overlap or invalid condition is found
      }
    }
    return true; // Validation passed
  };
  

  const handleStartChange = (day, index, startValue) => {

    let  timeFields = dayTimeFields ;

    const updatedFields = timeFields[day].map((timePair, i)=>
      i === index ? { ...timePair, start: startValue, end: timePair.end} : timePair
    )
    timeFields[day] = updatedFields

    setDayTimeFields(timeFields)

    setDayTimeFields(prevState =>{
      return{
        ...prevState,
        [day]:updatedFields
      }
    })

    const validateTime = validateTimeSlot(day , index , timeFields ) ;
    if (!validateTime) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [day]: {
          ...prevErrors[day],
          [index]: 'This time slot overlaps with an existing slot.',
        },
      }));
      setIsDisable(true);
    }else{
      setErrors(prevErrors => ({
        ...prevErrors,
        [day]: {
          ...prevErrors[day],
          [index]: '',
        },
      }));
      setIsDisable(false);
    }
  };
  
  const handleEndChange = (day, index, value) => {
    let  timeFields = dayTimeFields ;

    const updatedFields = timeFields[day].map((timePair, i)=>
      i === index ? { ...timePair, start: timePair.start, end: value} : timePair
    )
    timeFields[day] = updatedFields

    setDayTimeFields(timeFields)

    setDayTimeFields(prevState =>{
      return{
        ...prevState,
        [day]:updatedFields
      }
    })

    const validateTime = validateTimeSlot(day , index , timeFields ) ;
    if (!validateTime) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [day]: {
          ...prevErrors[day],
          [index]: 'This time slot overlaps with an existing slot.',
        },
      }));
      setIsDisable(true);
    }else{
      setErrors(prevErrors => ({
        ...prevErrors,
        [day]: {
          ...prevErrors[day],
          [index]: '',
        },
      }));
      setIsDisable(false);
    }
    
  };
  

  // const handleEndChange = (day, index, endValue) => {
  //   setDayTimeFields(prevState => {
  //     // Get the current start value for the slot
  //     const currentStartValue = prevState[day][index].start;
  //     const newStart = moment(currentStartValue, 'hh:mma');
  //     const newEnd = moment(endValue, 'hh:mma');
  
  //     // Validate the new time range
  //     const invalidTime = validateTimeRange(prevState, day, index, newStart, newEnd);
  
  //     if (invalidTime) {
  //       setErrors(prevErrors => ({
  //         ...prevErrors,
  //         [day]: {
  //           ...prevErrors[day],
  //           [index]: 'This time slot overlaps with an existing slot or is invalid.',
  //         },
  //       }));
  //       setIsDisable(true);
  //       return prevState; // Prevent state update if validation fails
  //     }
  
  //     // If valid, clear errors and update the state
  //     setErrors(prevErrors => ({
  //       ...prevErrors,
  //       [day]: {
  //         ...prevErrors[day],
  //         [index]: '',
  //       },
  //     }));
  //     setIsDisable(false);
  
  //     // Update only the end time
  //     return {
  //       ...prevState,
  //       [day]: prevState[day].map((timePair, i) =>
  //         i === index ? { ...timePair, end: endValue } : timePair
  //       ),
  //     };
  //   });
  // };




  // Validate Availability Before Saving
  const validateAvailability = () => {
    let error = '';
    // Check if time zone is selected
    if (!selectedTimeZone) {
      error = "Please select a time zone.";
    }
    // Check if at least one day is selected
    const anyDaySelected = Object.values(dayCheckboxes).some(isChecked => isChecked);
    if (!anyDaySelected) {
      error = "Please select at least one day.";
    }
    // Validate each selected day
    Object.keys(dayTimeFields).forEach(day => {
      if (dayCheckboxes[day]) {
        dayTimeFields[day].forEach(({ start, end }, index) => {
          const start24 = convertTo24HourFormat(start);
          const end24 = convertTo24HourFormat(end);
          if (start24 >= end24) {
            error = `End time must be after start time for ${day} interval ${index + 1}.`;
          }
        });
      }
    });
    if (error) {
      toast.error(error, {
        position: 'top-center',
      });
      return false;
    }
    return true;
  };

  // Handle Save Action
  const handleSave = async () => {
    if (!validateAvailability()) {
      return; // Stop if validation fails
    }
    const availabilityId = uuidv4(); // Generate a unique ID for the availability
    const availabilityData = [];
    Object.keys(dayTimeFields).forEach(day => {
      if (dayCheckboxes[day]) {
        const dateForDay = getDateForDay(day);
        dayTimeFields[day].forEach(timePair => {
          const startMoment = dateForDay.clone().set({
            hour: moment(timePair.start, 'hh:mma').hour(),
            minute: moment(timePair.start, 'hh:mma').minute(),
          });
          const endMoment = dateForDay.clone().set({
            hour: moment(timePair.end, 'hh:mma').hour(),
            minute: moment(timePair.end, 'hh:mma').minute(),
          });

          // Calculate the interval times
          const intervalMinutes = props.event.duration; // e.g., 15 minutes
          let currentMoment = startMoment.clone();

          while (currentMoment.isBefore(endMoment)) {
            const intervalEndMoment = currentMoment.clone().add(intervalMinutes, 'minutes');
            if (intervalEndMoment.isAfter(endMoment)) {
              break; // Stop if the interval end time exceeds the overall end time
            }
            availabilityData.push({
              scheduleId: uuidv4(), // Unique ID for each interval
              timeZone: selectedTimeZone,
              start: currentMoment.toString(), // ISO format
              end: intervalEndMoment.toString(), // ISO format
              event_start:timePair.start,
              event_end:timePair.end,
              event_id: props.event.id
            });
            currentMoment = intervalEndMoment; // Move to the next interval
          }
          // if (repeatOption === 'weekly') {
          //   for (let i = 1; i <= repeatCount; i++) {
          //     const weeklyStart = startMoment.clone().add(i, 'weeks');
          //     const weeklyEnd = endMoment.clone().add(i, 'weeks');
          //     availabilityData.push({
          //       scheduleId: uuidv4(),
          //       timeZone: selectedTimeZone,
          //       start: weeklyStart.toString(),
          //       end: weeklyEnd.toString(),
          //     });
          //   }
          // } else if (repeatOption === 'monthly') {
          //   for (let i = 1; i <= repeatCount; i++) {
          //     const monthlyStart = startMoment.clone().add(i, 'months');
          //     const monthlyEnd = endMoment.clone().add(i, 'months');
          //     availabilityData.push({
          //       scheduleId: uuidv4(),
          //       timeZone: selectedTimeZone,
          //       start: monthlyStart.toString(),
          //       end: monthlyEnd.toString(),
          //     });
          //   }
          // }
        });
      }
    });
    const payload = {
      id: availabilityId,
      timeZone: selectedTimeZone,
      availability: availabilityData,
      meetingDuration: props.event.duration,
      event_id : props.event.id
    };
    dispatch(createSchedule({ schedules: payload }))
   
  };

  // Convert 12-Hour Time Format to 24-Hour Format
  const convertTo24HourFormat = (time12h) => {
    let [time, modifier] = time12h.split(/(am|pm)/i);
    let [hours, minutes] = time.trim().split(':');

    if (hours === '12') {
      hours = '00';
    }
    if (modifier.toLowerCase() === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours.toString().padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  // console.log("selectedTimeZone" , selectedTimeZone)

  const getTimeZoneOffset = (zone) => {
    const currentTime = moment();
    const offset = currentTime.tz(zone).format('Z'); // Get UTC offset for the timezone
    return `(UTC${offset}) ${zone}`;
  };


  const handleBackButton = () =>{
    dispatch(setSchedules());
    props.hideAvailability();
  }

  return (
    <div className='p-5 container'>
      <Row className='justify-content-center'>
        <Col sm={12} lg={7}>
          <Button variant="primary" onClick={handleBackButton}>Back</Button>
          <Form.Group className="mb-12 form-group">
              <Form.Select value={selectedTimeZone} onChange={handleTimeZoneChange}>
                <option value={moment.tz.guess()}>{moment.tz.guess()}</option>
                {
                  timeZones.map((tz, index) => (
                    <option key={index} value={tz.Description}>
                      {/* {`${tz.Description} ${tz.Location}`} */}
                      {getTimeZoneOffset(tz)}
                    </option>
                  ))
                }
              </Form.Select>
          </Form.Group>
          <div className='custom--add--times'> 
            {/* Days of the Week */}
            <ListGroup>
              {['mon', 'tue', 'wed', 'thu', 'fri', 'sat','sun'].map(day => (
                <ListGroup.Item key={day}>
                  {/* Day Switch */}
                  <span className='day--checkbox'>
                    <Form.Check
                      type='switch'
                      id={day}
                      label={day.charAt(0).toUpperCase() + day.slice(1)}
                      checked={dayCheckboxes[day]}
                      onChange={() => handleCheckboxChange(day)}
                    />
                  </span>

                  {/* Time Slots for the Day */}
                  {dayCheckboxes[day] && (
                    <Form>
                      <div className="time--slots">
                        {dayTimeFields[day].map((timePair, index) => (
                          <div key={index} className='single--row'>
                            {/* Start Time Selection */}
                            <Form.Group className="form-group mb-0 me-2">
                              <Form.Select
                                value={timePair.start}
                                onChange={(e) => handleStartChange(day, index, e.target.value)}
                              >
                                {timeOptions.map(option => (
                                  <option key={option} value={option}>{option}</option>
                                ))}
                              </Form.Select>
                              {errors[day] && errors[day][index] && (
                                <span className="error-message" style={{ color: 'red' }}>
                                  {errors[day][index]}
                                </span>
                              )}
                            </Form.Group>

                            {/* End Time Selection */}
                            <Form.Group className="form-group mb-0">
                              <Form.Select
                                value={timePair.end}
                                onChange={(e) => handleEndChange(day, index, e.target.value)}
                              >
                                {endTimeOptions.map((option, ind) => (
                                  <option key={ind} value={option}>{option}</option>
                                ))}
                              </Form.Select>
                            </Form.Group>

                            {/* Remove Time Slot Button */}
                            <span className='remove--interval' onClick={() => handleRemoveTimeField(day, index)}>
                              <MdClose />
                            </span>
                          </div>
                        ))}
                      </div>

                      {/* Add Time Slot Button */}
                      <span className='action--icons'>
                        <span className='add--interval' onClick={() => handleAddTimeField(day)}>
                          <FiPlus />
                        </span>
                      </span>
                    </Form>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Button className='add--interval mt-3' disabled={isDisable} onClick={(e) => isDisable ? null : handleSave(e)}>Save</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default MeetingScheduler;


