import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Modal, Button, Row, Col, InputGroup, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import * as Auth from "../../helpers/auth";
import {createEvent , getEvents , setSchedules} from "../../redux/actions/schedule.action";
import MeetingScheduler from "./Scheduler";


function AddEvent() {

  const [ showMeeting , setShowMeeting] = useState(false);
  const [loading , setLoading] = useState(false);
  const [eventCreateLoader , setEventCreateLoader] = useState(false)
  const [showEventModel , setShowEventModel] = useState(false)
  const [event , setEvent] = useState({duration:"15" , amount:'' , name:'' , user_id:''})
  const [eventError , setEventError] = useState({name: false, amount: false,});
  const [eventList , setEventList] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState();
  const scheduleState = useSelector(state => state.schedule);
  const navigate = useNavigate();
  const dispatch =  useDispatch();

  useEffect(()=>{
    getLoggedUser()
    dispatch(setSchedules());
  },[])

  useEffect(()=>{
    if(scheduleState && scheduleState.events){
      setEventList(scheduleState.events.schedules)
      setLoading(false);
    }

    if(scheduleState && scheduleState.eventCreated){
      setShowEventModel(false)
      if(scheduleState.eventCreated.success){
        toast.success('Event created')
        resetEvents();
      }
    }
    if(scheduleState && scheduleState.createEventError){
      setEventCreateLoader(false)
      toast.error('Somthing went wrong')
    }
  },[scheduleState])

  const getLoggedUser = async () => {
    const userData = Auth.decodedLoggedUser();
    dispatch(getEvents({userId:userData.aud}))
    setLoading(true)
    setEvent({...event, user_id:userData.aud})
  }


  const resetEvents = () =>{
    const userData = Auth.decodedLoggedUser();
    dispatch(getEvents({userId:userData.aud}))
    setLoading(true)
    let updateEvent = event
    updateEvent.name = ''
    updateEvent.duration = '15'
    updateEvent.amount = ''
    updateEvent.user_id = userData.aud
    setEvent(updateEvent)
    setEventCreateLoader(false)
  }



  const handleCloseEventModel = () =>{
    setShowEventModel(false)
  }

  // Function to handle input change and update state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount") {
      const regex = /^[1-9]$|^\d+(\.\d{0,2})?$/; // Regex to allow valid amounts
      if (regex.test(value)) {
        // If amount is valid, update the state
        setEvent((prevData) => ({
          ...prevData,
          [name]: value,  // Update the totalAmount field
        }));
        setEventError({...eventError , [name]:''})
      } else {
        // If invalid amount, reset the totalAmount value
        setEvent((prevData) => ({
          ...prevData,
          [name]: '',  // Clear the totalAmount field
        }));
        setEventError({...eventError , [name]:''})
      }
    } else {
      // For other fields, update the state as usual
      setEvent((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setEventError({...eventError , [name]:''})
    }
  
  };


  const validateForm = () => {
    const newErrors = {
      name: !event.name,       // Event name is required
      amount: !event.amount,   // Total amount is required
    };
    setEventError(newErrors);
    // Return true if there are no errors
    return !Object.values(newErrors).includes(true);
  };


  const handleSaveEvent = () => {
    // Validate the form fields before submission
    if (validateForm()) {
      setEventCreateLoader(true)
      dispatch(createEvent(event))
    } 
  };

  const createEventForm = () =>{
    return (
      <Modal show={showEventModel} onHide={handleCloseEventModel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-3">
            <Form.Group className="mb-3 form-group">
              <Form.Label>Event time</Form.Label>
              <Form.Select
                name="duration"
                value={event.duration}
                onChange={handleInputChange}
              >
                <option value="15">15 minutes meeting</option>
                <option value="30">30 minutes meeting</option>
                <option value="45">45 minutes meeting</option>
                <option value="60">60 minutes meeting</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Event name</Form.Label>
              <Form.Control
                aria-label=""
                type="text"
                name="name"
                value={event.name}
                onChange={handleInputChange}
              />
              {eventError.name && (<span className="error error-massege text-danger" >Event name is required.</span>)}
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Total amount</Form.Label>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  type="text"
                  name="amount"
                  value={event.amount}
                  onChange={handleInputChange}
                />
              </InputGroup>
              {eventError.amount && (<span  className="error error-massege text-danger">Event name is required.</span>)}
            </Form.Group>
            <Button className="add--interval ms-auto" disabled={eventCreateLoader} onClick={()=>handleSaveEvent()}>{eventCreateLoader ? 'wait' :'Save'}</Button>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }

  const handleEventChange = (e) =>{
    setSelectedEventId(e.target.id)
  }


  const renderAvailability = () => {
    if (selectedEventId) {
      setShowMeeting(true); // Show the meeting scheduler
    } else {
      toast.error('Please select an event'); // Show error if no event is selected
    }
  };



  return (
    <>
     {!showMeeting && 
      <div className="p-5 container">
        <Row className="justify-content-center">
          <Col sm={12} lg={8}>
            <Row>
              <Col sm={6}>
                <Button variant="primary" onClick={() => navigate(-1)}>
                  Back
                </Button>
              </Col>
              <Col sm={6} className="text-end">
                <Button variant="secondary" onClick={()=>setShowEventModel(true)}>Add Event</Button>
              </Col>
            </Row>
           
            <div className="custom--add--times event--form">
              {(loading)&& <Spinner/>}
              {(!loading && eventList && eventList.length > 0)&&
                <>
                    <Form>
                      {eventList.map((event, index) => (
                        <Form.Check
                          key={index}
                          type="radio"
                          label={<span>{event.name} <small>{event.duration} min</small></span>}
                          id={event.id}
                          checked={Number(selectedEventId) === Number(event.id)} // Compare selectedEventId with event ID
                          onChange={handleEventChange}
                        />
                      ))}
                    </Form>
                  <Button className="mt-3" variant="primary" onClick={renderAvailability}>Set Availability</Button>
                </>
              }
            </div>
          </Col>
        </Row>
      </div>
    }
    {showEventModel && createEventForm()}
    {showMeeting && selectedEventId && (
        <MeetingScheduler
          event={eventList.find(event => Number(event.id) === Number(selectedEventId))}
          userId = {event.user_id}
          hideAvailability={() => setShowMeeting(false)} // This will hide the MeetingScheduler
        />
    )}
    </>
  );
}

export default AddEvent;
