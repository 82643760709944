import DiscoverMain from "../Components/Discover/DiscoverPage";
import MarketMain from "../Components/Marketplace/MarketplacePage";
import CreateShopForm from "../Components/Shop/CreateShopPage";
import ShopMain from "../Components/Shop/ShopPage";
import SingleProduct from "../Components/Marketplace/ProductSingle";
import SingleProductCategory from "../Components/Marketplace/ProductCategory";
import CreatePostForm from "../Components/Create/CreateForm";
import AudioPostsMain from "../Components/Audio/AudioPage";
import VisualPostsMain from "../Components/Visual/VisualPage";
import TextPostsMain from "../Components/Text/TextPage";
import VideoPostsMain from "../Components/Video/VideoPage";
import LiveVideoMain from "../Components/Video/LiveVideos";
import VideoSingleMain from "../Components/Video/VideoSingle";
import LiveVideoSingle from "../Components/Video/LiveVideoSingle";
import VisualPostSingle from "../Components/Visual/VisualSingle";
import TextPostSingle from "../Components/Text/TextSingle";
import RealmDisclosure from "../Components/Realms/DisclosureRealm";
import AudioPostSingle from "../Components/Audio/AudioSingle";
import RealmsMain from "../Components/Realms/RealmsMain";
import Messenger from "../Components/Messenger";
import ProfileMainPage from "../Components/Profile/ProfilePage";
import EditProfilePage from "../Components/Profile/EditProfile";
import ShopOrders from "../Components/Profile/ShopProfile/OrdersPage";
import ShopPurchases from "../Components/Profile/ShopProfile/PurchasesPage";
import ShopDrafts from "../Components/Profile/ShopProfile/DraftsPage";
import ShopRefund from "../Components/Profile/ShopProfile/RefundPage";
import MyShop from "../Components/Profile/ShopProfile/MyShopPage";
import LoginMain from "../Components/Auth/LoginPage";
import SignupMain from "../Components/Auth/SignupPage";
import { Navigate } from "react-router-dom";
import EditText from "../Components/Text/EditText";
import EditVisual from "../Components/Visual/EditVisual";
import EditVideo from "../Components/Video/EditVideo";
import EditAudio from "../Components/Audio/EditAudio";
import EditSingleAudio from "../Components/Audio/EditSingleAudio";
import EditShopForm from "../Components/Shop/EditShopPage";
import CreateProduct from "../Components/Marketplace/CreateProduct";
import CartMain from "../Components/Shop/CartPage";
import EditProduct from "../Components/Marketplace/EditProduct";
import NotificationMain from "../Components/Notify/NotificationPage";
import Checkout from "../Components/Checkout";
import GlobalSearch from "../Components/GlobalSearch";
import EditLiveVideo from "../Components/Video/EditLiveVideo";
import StreamMain from "../Components/Stream/StreamPage";
import MeetingSchudler from "../Components/MeetingScheduler";
import BookCreater from "../Components/MeetingScheduler/BookCreater";
import VideoChatMain from "../Components/VideoChat";
import BookingCalender from "../Components/MeetingScheduler/BookingScheduler";
import AddEvent from "../Components/MeetingScheduler";
import BookMeetingsList from "../Components/MeetingScheduler/BookMeetingsList";


const all = 'all' ;

const commonRouter = [
    {
        type: 'page',
        name: 'discover',
        key: 'DISCOVER',
        component: <DiscoverMain/>,
        route: '/discover',
        access: all,
    },
    {
        type: 'page',
        name: 'marketplace',
        key: 'MARKETPLACE',
        component: <MarketMain/>,
        route: '/marketplace',
        access: all,
    },
    {
        type: 'page',
        name: 'product-shop',
        key: 'PRODUCTSHOP',
        component: <SingleProductCategory/>,
        route: '/marketplace/product/:slug',
        access: all,
    },
    {
        type: 'page',
        name: 'product-single',
        key: 'PRODUCTSINGLE',
        component: <SingleProduct/>,
        route: '/marketplace/single-product/:slug',
        access: all,
    },
    // {
    //     type: 'page',
    //     name: 'shop',
    //     key: 'SHOP',
    //     component: <ShopMain/>,
    //     route: '/shop',
    //     access: all,
    // },
    {
        type: 'page',
        name: 'audio',
        key: 'AUDIO',
        component: <AudioPostsMain/>,
        route: '/audio',
        access: all,
    },
    {
        type: 'page',
        name: 'audio-single',
        key: 'AUDIOSINGLE',
        component: <AudioPostSingle/>,
        route: '/audio/single/:slug',
        access: all,
    },
    {
        type: 'page',
        name: 'audio-album',
        key: 'AUDIOALBUM',
        component: <AudioPostSingle/>,
        route: '/audio/album/:slug',
        access: all,
    },
    {
        type: 'page',
        name: 'visual',
        key: 'VISUAL',
        component: <VisualPostsMain/>,
        route: '/visual',
        access: all,
    },
    {
        type: 'page',
        name: 'visual-single',
        key: 'VISUALSINGLE',
        component: <VisualPostSingle/>,
        route: '/visual/:slug',
        access: all,
    },
    {
        type: 'page',
        name: 'text',
        key: 'TEXT',
        component: <TextPostsMain/>,
        route: '/text',
        access: all,
    },
    {
        type: 'page',
        name: 'text-single',
        key: 'TEXTSINGLE',
        component: <TextPostSingle/>,
        route: '/text/:slug',
        access: all,
    },
    {
        type: 'page',
        name: 'video',
        key: 'VIDEO',
        component: <VideoPostsMain/>,
        route: '/video',
        access: all,
    },
    {
        type: 'page',
        name: 'video-single',
        key: 'VIDEOSINGLE',
        component: <VideoSingleMain/>,
        route: '/video/:slug',
        access: all,
    },
    {
        type: 'page',
        name: 'live-video',
        key: 'LIVEVIDEO',
        component: <LiveVideoMain/>,
        route: '/live-video',
        access: all,
    },
    {
        type: 'page',
        name: 'live-video',
        key: 'LIVEVIDEOSINGLE',
        component: <LiveVideoSingle/>,
        route: '/live-video/:slug',
        access: all,
    },
    {
        type: 'page',
        name: 'realm',
        key: 'REALM',
        component: <RealmsMain/>,
        route: '/realm',
        access: all,
    },
    {
        type: 'page',
        name: 'disclosure',
        key: 'DISCLOSURE',
        component: <RealmDisclosure/>,
        route: '/realm/:slug',
        access: all,
    },
    {
        type:'page',
        name:'search',
        kay:'SEARCH',
        component:<GlobalSearch/>,
        route: '/search',
        access: all ,
    },
    {
        type: 'page',
        name: 'profile',
        key: 'PROFILE',
        component: <ProfileMainPage/>,
        route: '/:userName',
        access: all,
    },
];


const publicRoutes = [
    ...commonRouter ,
    {
        type: "other",
        name: 'ROOT',
        key: 'ROOT',
        component: <Navigate to="/discover" />,
        route: "/",
        access: all
    },
    {
        type: 'other',
        name: 'Un Match',
        key: "UN_MATCH",
        component: <Navigate to="/discover" />,
        route: "*",
        access: all
    },
    {
        
        type: 'page',
        name: 'sign-in',
        key: 'SIGNIN',
        component: <LoginMain/>,
        route: '/login',
        access: all,
        
    },
    {
        
        type: 'page',
        name: 'register',
        key: 'REGISTER',
        component: <SignupMain/>,
        route: '/register',
        access: all,
        
    },
    // {
    //     type: 'page',
    //     name: 'profile',
    //     key: 'PROFILE',
    //     component: <ProfileMainPage/>,
    //     route: '/:userName',
    //     access: all,
    // },
];

const privateRoutes = [
    ...commonRouter,
    {
        type: "other",
        name: 'ROOT',
        key: 'ROOT',
        component: <Navigate to="/stream" />,
        route: "/",
        access: all
    },
    {
        type: 'other',
        name: 'Un Match',
        key: "UN_MATCH",
        component: <Navigate to="/stream" />,
        route: "*",
        access: all
    },
    {
        type: 'page',
        name: 'stream',
        key: 'STREAM',
        component: <StreamMain />,
        route: '/stream',
        access: all,
    },
    {
        type: 'page',
        name: 'create',
        key: 'CREATE',
        component: <CreatePostForm/>,
        route: '/create',
        access: all,
        
    },
    {
        type: 'page',
        name: 'edit-text',
        key: 'EDITTEXT',
        component: <EditText/>,
        route: '/edit-text/:slug',
        access: all,
        
    },
    {
        type: 'page',
        name: 'edit-live',
        key: 'EDITLIVE',
        component: <EditLiveVideo/>,
        route: '/live',
        access: all,

    },
    {
        type: 'page',
        name: 'edit-visual',
        key: 'EDITVISUAL',
        component: <EditVisual/>,
        route: '/edit-visual/:slug',
        access: all,
        
    },

    {
        type: 'page',
        name: 'edit-video',
        key: 'EDITVIDEO',
        component: <EditVideo/>,
        route: '/edit-video/:slug',
        access: all,
        
    },

    {
        type: 'page',
        name: 'edit-album-audio',
        key: 'EDITAUDIO',
        component: <EditAudio/>,
        route: '/edit/audio/album/:slug',
        access: all,
        
    },
    {
        type: 'page',
        name: 'edit-single-audio',
        key: 'EDITSINGLE',
        component: <EditSingleAudio/>,
        route: '/edit/audio/single/:slug',
        access: all,
        
    },
    {
        type: 'page',
        name: 'order',
        key: 'ORDER',
        component: <ShopOrders/>,
        route: '/orders',
        access: all,
        
    },
    {
        type: 'page',
        name: 'purchases',
        key: 'PURCHASE',
        component: <ShopPurchases/>,
        route: '/purchases',
        access: all,
        
    },
    {
        type: 'page',
        name: 'draft',
        key: 'DRAFT',
        component: <ShopDrafts/>,
        route: '/drafts',
        access: all,
        
    },
    {
        type: 'page',
        name: 'refund',
        key: 'REFUND',
        component: <ShopRefund/>,
        route: '/refund-requests',
        access: all,
        
    },
    {
        type: 'page',
        name: 'myshop',
        key: 'MYSHOP',
        component: <MyShop/>,
        route: '/my-shop',
        access: all,
    },
    {
        type: 'page',
        name: 'createShop',
        key: 'CREATESHOP',
        component: <CreateShopForm/>,
        route: '/create-shop',
        access: all,
    },
    {
        type: 'page',
        name: 'cart',
        key: 'CART',
        component: <CartMain/>,
        route: '/cart',
        access: all,
        
    },

    {
        type: 'page',
        name: 'createProduct',
        key: 'CREATEPRODUCT',
        component: <CreateProduct/>,
        route: '/create-product',
        access: all,
    },
    {
        type: 'page',
        name: 'editShop',
        key: 'EDITSHOP',
        component: <EditShopForm/>,
        route: '/edit-shop/:slug',
        access: all,
    },
    {
        type: 'page',
        name: 'editProduct',
        key: 'EDITPRODUCT',
        component: <EditProduct/>,
        route: '/edit-product/:slug',
        access: all,
    },
    {
        type: 'page',
        name: 'messenger',
        key: 'MESSENGER',
        component: <Messenger/>,
        route: '/messenger',
        access: all,
    },
    {
        type: 'page',
        name: 'profile-setting',
        key: 'PROFILESETIING',
        component: <EditProfilePage/>,
        route: '/profile-settings',
        access: all,
    },
    {
        type: 'page',
        name: 'notify',
        key: 'NOTIFICATION',
        component: <NotificationMain/>,
        route: '/notifications',
        access: all,
    },
    {
        type: 'page',
        name: 'checkout',
        key: 'CHECKOUT',
        component: <Checkout/>,
        route: '/checkout',
        access: all,
    },
    {
        type: 'page',
        name: 'event',
        key: 'EVENT',
        component: <AddEvent/>,
        route: '/schedule',
        access: all,
    },

    {
        type: 'page',
        name: 'scheduler',
        key: 'SCHEDULER',
        component: <MeetingSchudler/>,
        route: '/availability',
        access: all,
    },

    {
        type: 'page',
        name: 'events',
        key: 'EVENTS',
        component: <BookingCalender/>,
        route: '/booking/:id',
        access: all,
    },
    {
        type: 'page',
        name: 'CallMeeting',
        key: 'CALLMEETING',
        component: <VideoChatMain/>,
        route: '/meet'
    },
    {
        type: 'page',
        name: 'MeetingList',
        key: 'MeetingList',
        component: <BookMeetingsList/>,
        route: '/meetings'
    }
    
]

export {privateRoutes,publicRoutes};