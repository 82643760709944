import React, { useState, useEffect } from "react";
import { Form,Button, Row, Col , Spinner } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {getEvents} from "../../redux/actions/schedule.action";
import { toast } from 'react-toastify';
import BookingCalendar from "./BookingModel";


function BookingScheduler() {
    const [ showSlots , setShowSlots] = useState(false);
    const [loading , setLoading] = useState(false);
    const [eventList , setEventList] = useState([]);
    const [eventError , setEventError] = useState(false)
    const [selectedEventId, setSelectedEventId] = useState();
    const scheduleState = useSelector(state => state.schedule);
    const navigate = useNavigate();
    const dispatch =  useDispatch();
    const { id } = useParams();

    useEffect(()=>{
        getMeetingsEvent()
    },[])

    useEffect(()=>{
        if(scheduleState && scheduleState.events){
            if(scheduleState.events.schedules && scheduleState.events.schedules.length > 0 ){
                setEventList(scheduleState.events.schedules)
                setLoading(false);
            }else{
                setEventError(true)
            }
        }
    },[scheduleState])

    const getMeetingsEvent = async () => {
        dispatch(getEvents({userId:id}))
        setLoading(true)
    }


    const handleEventChange = (e) =>{
        setSelectedEventId(e.target.id)
    }


      const renderSlots = () => {
        if (selectedEventId) {
          setShowSlots(true); // Show the meeting scheduler
        } else {
          toast.error('Please select an event'); // Show error if no event is selected
        }
      };


  


  const eventSingle = eventList.find(event => Number(event.id) === Number(selectedEventId))

  console.log("eventSingle" , eventSingle)

  return (
    <>
     {!showSlots && 
      <div className="p-5 container">
        <Row className="justify-content-center">
          <Col sm={12} lg={8}>
            <Row>
              <Col sm={6}>
                <Button variant="primary" onClick={() => navigate(-1)}>
                  Back
                </Button>
              </Col>
            </Row>
            <div className="custom--add--times event--form">
                {(loading)&& <Spinner/>}
                {(!loading && eventList && eventList.length > 0)&&
                    <>
                        <Form>
                            {eventList.map((event, index) => (
                                <Form.Check
                                    key={index}
                                    type="radio"
                                    label={<span>{event.name} <small>{event.duration} min</small></span>}
                                    id={event.id}
                                    checked={Number(selectedEventId) === Number(event.id)} // Compare selectedEventId with event ID
                                    onChange={handleEventChange}
                                />
                            ))}
                        </Form>
                    <Button className="mt-3" variant="primary" onClick={renderSlots}>Book Slots</Button>
                    </>
                }
                {eventError && <h3> Events not found </h3>}
            </div>
          </Col>
        </Row>
      </div>
    }
    {showSlots && selectedEventId && (
        <BookingCalendar
          event={eventList.find(event => Number(event.id) === Number(selectedEventId))}
          userId = {id}
          hideAvailability={() => setShowSlots(false)} // This will hide the MeetingScheduler
        />
    )}
    </>
  );
}

export default BookingScheduler;
