
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, ListGroup, Button , Modal} from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import Calendar from 'react-calendar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import { getSchedules, getAvailableTimeSlots, bookSchedule , setSchedules} from "../../redux/actions/schedule.action";
import * as Auth from '../../helpers/auth';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import timezone from '../../utils/timezone';
import { IoMdTime } from "react-icons/io";
import moment from 'moment-timezone';

function BookingCalendar(props) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [events, setEvents] = useState([]);
    const [timeSlots, setTimeSlots] = useState([]);
    const [loader, setLoader] = useState(false);
    const [ showError , setShowError] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [isActive , setIsActive] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [isDisable , setIsDisable] = useState(false)
    const [userId , setUserId]= useState('')
    const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess()); // Default to user's local time zone
    const [showModal, setShowModal] = useState(false);
    const [bookingDetails, setBookingDetails] = useState(null);
    const timeZones = moment.tz.names();
    const minimumDate = new Date();
    const navigate = useNavigate()
    


    const scheduleState = useSelector(state => state.schedule);

    const getLoggedUser = async () => {
        const userData = Auth.decodedLoggedUser();
        setUserId(Number(userData.aud))
        // setUserName(userData.firstName)
    }

    const resetStates = () => {
        setSelectedDate('');
        setSelectedSlot(null);
        setTimeSlots([]);
        setIsActive(false);
        setIsDisable(false);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    // Fetch schedules and update state
    useEffect(() => {
        handleGetSchedules();
        getLoggedUser();
    }, []);


    useEffect(() => {
        if (scheduleState.error) {
            toast.error(scheduleState.error.message);
            setLoader(false);
            setIsDisable(false)
        }
        if (!scheduleState.error) {
            if (scheduleState.schedules && scheduleState.schedules.success) {
                if (scheduleState.schedules.schedules && scheduleState.schedules.schedules.length > 0) {
                    const filteredSchedules = scheduleState.schedules.schedules.filter(schedule => schedule.booked_scheduler === null);
                    const convertedSchedules = filteredSchedules.map(schedule => ({
                        ...schedule,
                        start_time: new Date(schedule.event_start_time),
                        end_time: new Date(schedule.event_end_time),
                        timezone:schedule.timezone ,
                        meetingDuration: schedule.meeting_duration,
                        schedule_id:schedule.schedule_id,
                    }));
                    setEvents(convertedSchedules);
                }else{
                    setShowError(true)
                }
            }
            if(scheduleState.scheduleBooked && scheduleState.scheduleBooked.success){
                // Reset states after successful booking
                setBookingDetails(scheduleState.scheduleBooked.meetingDetail); // Store booking details
                toast.success('Meeting booked . ')
                navigate('/meetings')
            }
        }
    },[scheduleState]);

    useEffect(() => {
        // if (selectedDate) {
            handleAvailableTimeSlots(selectedDate);
        // }
    }, [selectedDate, selectedTimeZone]);  // Recompute when selected date or time zone changes

    const handleGetSchedules = () => {
        const data = { userId: Number(id),eventId: props.event.id };
        dispatch(getSchedules(data))
       
    };

    const convertToTimeZone = (time, userTimeZone, eventTimeZone) => {
        // Safely extract the time zone region from the input strings
        const userTimeZoneRegion = userTimeZone.includes(') ') ? userTimeZone.split(') ')[1] : userTimeZone;
        const eventTimeZoneRegion = eventTimeZone.includes(') ') ? eventTimeZone.split(') ')[1] : eventTimeZone;
        // Convert the time from the event's time zone to the user's time zone
        const convertedTime = moment.tz(time, eventTimeZoneRegion).tz(userTimeZoneRegion).format('YYYY-MM-DD HH:mm');
        return convertedTime;
    };
    

    // Handle when the user clicks on a date in the calendar
    const handleDayClick = (date) => {
        setSelectedDate(date);
        handleAvailableTimeSlots(date);
        if(date){
            setIsActive(true);
        }
    };

    // Handle available time slots based on selected date and time zone
    const handleAvailableTimeSlots = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const filteredEvents = events.filter(event => moment(event.event_start_time).isSame(formattedDate, 'day'));
        const availableSlots = filteredEvents.map(event => {
            return {
                start: convertToTimeZone(event.event_start_time, selectedTimeZone , '(UTC+05:30) Asia/Kolkata'),
                end: convertToTimeZone(event.event_end_time, selectedTimeZone , '(UTC+05:30) Asia/Kolkata'),
                timezone:event.timezone ,
                meetingDuration: event.meetingDuration ,
                schedule_id:event.schedule_id
            };
        });
        setTimeSlots(availableSlots);
    };

    const showTimeList = () => {
        return (
          <div className={isActive ? 'book--time--list show--book--time' : 'book--time--list'}>
            <h5>{moment(selectedDate).format('ll')}</h5>
            <ListGroup>
                {timeSlots.map((slot, index) => (
                    <ListGroup.Item key={index}>
                    <input
                        type="radio"
                        id={`slot${index}`}
                        name="radio-group"
                        value={index}
                        checked={selectedSlot === index}
                        onChange={() => handleSlotSelect(index)}
                    />
                    <label htmlFor={`slot${index}`}>
                        {/* {`${slot.start} to ${slot.end}`} */}
                        {`${moment(slot.start).format('h:mm A')}`}
                    </label>
                    <Button variant="primary"  disabled={isDisable} onClick={() => handleBookSlot()}>Book</Button>
                    </ListGroup.Item>
                ))}
            </ListGroup>
          </div>
        );
    };

    // Handle time zone change
    const handleTimeZoneChange = (e) => {
        setSelectedTimeZone(e.target.value);
    };


    const getTimeZoneOffset = (zone) => {
        const currentTime = moment();
        const offset = currentTime.tz(zone).format('Z'); // Get UTC offset for the timezone
        return `(UTC${offset}) ${zone}`;
    };


    const isAvailable = ({ date }) => {
        if (!events || events.length === 0) {
            return true; // Disable all dates
        }
        const enabledDates = events.map(entry => moment(entry.start_time).startOf('day').format('YYYY-MM-DD'));
        const formattedDate = moment(date).startOf('day').format('YYYY-MM-DD');
        return !enabledDates.includes(formattedDate);
        
    }

    const handleBookSlot = () =>{
        const bookerTimeZoneData = timeSlots[selectedSlot] ;
        const payload = {slotDetail:bookerTimeZoneData , bookerTimeZone:selectedTimeZone}
        const data = {
            user_id: userId,
            payload,
            schedule_id:bookerTimeZoneData.schedule_id,
            timezone:selectedTimeZone,
        }
        dispatch(bookSchedule(data));
        setIsDisable(true)
    }
    

    const handleSlotSelect = (index) => {
        setSelectedSlot(index);
    };


   


    const closeBookingModel = ()=>{
        setShowModal(false)
        handleGetSchedules();
        resetStates();
    }


    const BookingModal = () => (
        <Modal show={showModal} onHide={closeBookingModel}>
            <Modal.Header closeButton>
                <Modal.Title>Booking Confirmed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Booking successful!</p>
                <p>Details:</p>
                {bookingDetails && (
                    <ul>
                        <li> Meeting Start Time: {moment(bookingDetails.payload.slotDetail.start).format('lll')}</li>
                        <li>Time Zone: {bookingDetails.timezone}</li>
                        <p>We have shared meeting ID on your registered email address.</p>
                    </ul>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeBookingModel}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );



    const handleBack= () =>{
        dispatch(setSchedules());
        props.hideAvailability();
    }


    return (
        <div className="main-wraper-service-book pt-5">
            <Container>
                <Button variant="primary" onClick={handleBack}>
                  Back
                </Button>
                <Row className="justify-content-center">
                    <Col lg={12}>
                        <div className="booking--date">
                            <div className="meeting--time">
                                <h3>{props.event.name.charAt(0).toUpperCase() + props.event.name.slice(1)}</h3>
                                <h3><IoMdTime />{props.event.duration}</h3>
                            </div>
                            <div className="book--calender">
                                <p className="mb-1">Select Date To Book Appointment</p>
                                <Calendar onChange={handleDayClick} minDate={minimumDate} tileDisabled={isAvailable}/>
                                <Form.Group className="mb-0 mt-3 form-group">
                                    <label>Time Zone</label>
                                    <Form.Select onChange={handleTimeZoneChange} value={selectedTimeZone}>
                                        <option value={moment.tz.guess()}>{moment.tz.guess()}</option>
                                        {
                                            timeZones.map((tz, index) => (
                                            <option key={index} value={tz.Description}>
                                                {getTimeZoneOffset(tz)}
                                            </option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                                
                            </div>
                            <div className="time-slot-list">
                                    {showTimeList()}
                                </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <BookingModal/>
        </div>
    );
}

export default BookingCalendar;