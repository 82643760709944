import {useState , useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation , useParams , useNavigate} from 'react-router-dom';
import { Container, Row, Col, Tabs, Tab , OverlayTrigger , Tooltip , Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import ProfilePostsTab from './ProfileTabs/PostsTab';
import ProfileShopTab from './ProfileTabs/ShopTab';
import { toast} from "react-hot-toast";  
import 'bootstrap/dist/css/bootstrap.min.css';
import './profileStyle.css';
import '../../App.css';
import 'lightbox.js-react/dist/index.css'
import { getSingleUser } from '../../redux/actions/user.action';
import { decodedLoggedUser } from '../../helpers/auth';
import { checkHTML , convertToPlainText , valid} from '../../utils/validations';
import LightboxComponent from '../LightBoxComponent';
import { TbCalendarTime } from "react-icons/tb";
import { getSchedules } from '../../redux/actions/schedule.action';



const check = ['undefined' , undefined , null , 'null' , '']


function ProfileMainPage() {
    const{userName} = useParams();  
    const location = useLocation();
    const [user , setUser] = useState('')
    const [currentUser , setCurrentUser] = useState('')
    const [loader , setLoader] = useState(false);
    const [isLightBoxOpen , setLightBox] = useState(false);
    const [postMedia , setPostMedia] = useState('');
    const [schedules ,setSchedules] = useState([])
    const [tab, setTab] = useState('post');
    const [hideSideBar , setHideSideBar] =  useState(false);
    const auth = useSelector(state => state.auth);
    const userResult = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const scheduleState = useSelector(state => state.schedule);

    useEffect(()=>{
        dispatch(getSingleUser(userName))
        if (auth.loggedIn) {
            let token = decodedLoggedUser()
            setCurrentUser(token.aud)
        }
    },[userName])


    useEffect(()=>{
        if(userResult?.singleUser && userResult?.singleUser?.success){
            handleGetSchedules( userResult.singleUser.profile.id)
            setUser(userResult?.singleUser?.profile)
            setLoader(true)
        }
        if(userResult && userResult.userError){
            toast.error(userResult.userError, {
                position: 'top-center', 
            });
            setLoader(true)
        }
    },[userResult])


    // useEffect(()=>{
    //     if (scheduleState.schedules && scheduleState.schedules.success) {
    //         if (scheduleState.schedules.schedules && scheduleState.schedules.schedules.length > 0) {
    //             setSchedules(scheduleState.schedules.schedules)
    //         }
    //     }
    // },[scheduleState])

    const handleSelectTab = (tabName) => {
        setTab(tabName)
    }

    const handleGetSchedules = (userId) => {
        const data = { userId: Number(userId) };
        if (auth.loggedIn) {
            dispatch(getSchedules(data))
        }
    }


    const handleLightBox = (media) =>{
        const data = [{src:media}];
        setPostMedia(data)
        setLightBox(true)
        setHideSideBar(true)
    }

    const handleCloseLightBox = ()=>{
        setLightBox(false)
        setHideSideBar(false)
    }

    return (
        <>
            {isLightBoxOpen && 
                <LightboxComponent
                    isOpen={isLightBoxOpen}
                    images={postMedia}
                    onClose={() => handleCloseLightBox()}
                />
            }
            <PageHeader />
                <div className='page--content'>
                    {!hideSideBar && 
                        <SidebarMenu />
                    }
                <div className='discover--module common--wrapper Profile--page'>
                    <Container>
                        {loader 
                            ?
                                <>
                                {user 
                                
                                    ?   <>
                                            <Row>
                                                <Col sm={12}>
                                                    <div 
                                                        className='profile--banner'  
                                                        style={{
                                                            backgroundImage: `url(${user.cover || ''})`,
                                                            backgroundSize: 'cover', // to ensure the image covers the entire div
                                                            backgroundPosition: 'center' // to center the image
                                                        }}
                                                    >
                                                        <span className='profile--thumb' onClick={()=>handleLightBox(user.image)}>
                                                            <LazyLoadImage
                                                                width="100%"
                                                                placeholderSrc="/images/placeholder.gif"
                                                                effect="blur"
                                                                src={user.image || '/images/user.png'}
                                                                alt={user.name}
                                                            /> 
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col sm={12} className='text-center mt-4'>
                                                    <h4>{user.first || ''}</h4>
                                                    <p>{valid(user.bio)? checkHTML(user.bio) ? convertToPlainText(user.bio) : user.bio : ''}</p>
                                                    {/* {(Number(user.id) !== Number(currentUser) && schedules && schedules.length > 0)&& */}
                                                        <div className='calendar--icon mb-0'>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={<Tooltip id={`tooltip-top`}>Schedule Appointment</Tooltip>}
                                                            >
                                                                <Button variant='primary' onClick={()=>navigate(`/booking/${user.id}`)}><TbCalendarTime/></Button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    {/* } */}
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-center'>
                                                <Col sm={12}>
                                                    <div className='Tabs--horizontal'>
                                                        <Tabs 
                                                            mountOnEnter={true} 
                                                            unmountOnExit={true} 
                                                            activeKey={tab} 
                                                            id="profile--page--tabs" 
                                                            onSelect={(e) => handleSelectTab(e)}
                                                        >
                                                            <Tab eventKey="post" title="Posts">
                                                                <ProfilePostsTab user={user}/>
                                                            </Tab>
                                                            <Tab eventKey="shop" title="Shop">
                                                                <ProfileShopTab user={user}/>
                                                            </Tab>
                                                        </Tabs>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>   
                                    :   <h3> User Not Found </h3>    
                                }     
                                </>    
                            :   <div className='text-center spinner--loader'></div>
                            }        
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ProfileMainPage;