import React, { useEffect, useState } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMeetings } from "../../redux/actions/schedule.action";
import moment from 'moment';
import { useNavigate } from "react-router-dom";

function BookMeetingsList() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true); // State for handling the loader
    const scheduleState = useSelector(state => state.schedule);
    const navigate = useNavigate();
    
    useEffect(() => {
        dispatch(getMeetings()).finally(() => setLoading(false)); // Set loading to false after the data is fetched
    }, [dispatch]);

    return (
        <div>
            <Button onClick={()=>navigate(-1)}> Back</Button>
            <h2>Book Meetings List</h2>

            {loading ? (
                // Show the loader while fetching data
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                // Check if meetings array is null or empty
                scheduleState?.meetings?.meetings.length > 0 ? (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Amount</th>
                                <th>Start Time</th>
                                <th>Duration</th>
                                <th> Meeting With</th>
                                <th>Join Meeting</th>
                            </tr>
                        </thead>
                        <tbody>
                            {scheduleState.meetings.meetings.slice().reverse().map((meeting, index) => {
                                const creatorDetail = JSON.parse(meeting.creater_detail || '{}');
                                return(
                                    <tr key={meeting.id}>
                                        <td>{index + 1}</td>
                                        <td>${meeting.amount}</td>
                                        <td>{moment(meeting.start_time).format('YYYY-MM-DD HH:mm')}</td>
                                        <td>{meeting.meeting_duration} minutes</td>
                                        <td>
                                            {creatorDetail.firstname} {creatorDetail.lastname} <br />
                                            {creatorDetail.email}
                                        </td>
                                        <td>
                                            <Link style={{'color':'blue'}} to={meeting.meeting_link} target="_blank" rel="noopener noreferrer">
                                                Join
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                ) : (
                    // Show message if no meetings are found
                    <div className="text-center">
                        <p>No meetings found</p>
                    </div>
                )
            )}
        </div>
    );
}

export default BookMeetingsList;
